import axios, {AxiosResponse} from 'axios';
import {GetDiaryParams, GetDiaryResponse, PostNoteBody} from "../components/utilities/constants";

const url = process.env.REACT_APP_API_URL;


export default class childService {
    static getDiary(params: GetDiaryParams, id: string): Promise<GetDiaryResponse> {
        return axios.get(`${url}/child/${id}/diary`, { params }).then((response: AxiosResponse<GetDiaryResponse>) => {
            return response.data;
        });
    }

    static putDiary(params: PostNoteBody, noteId: string): Promise<any> {
        return axios.put(`${url}/note/${noteId}`, {
            notes: params.notes ?? undefined,
            food_first_course: params.food_first_course ?? undefined,
            food_second_course: params.food_second_course ?? undefined,
            food_side: params.food_side ?? undefined,
            feces_1_quality: params.feces_1_quality ?? undefined,
            feces_2_quality: params.feces_2_quality ?? undefined,
            milk: params.milk ?? undefined,
            nap_1_quality: params.nap_1_quality ?? undefined,
            nap_2_quality: params.nap_2_quality ?? undefined
        });
    }

    static postDiary(params: PostNoteBody, childId: string): Promise<any> {
        return axios.post(`${url}/child/${childId}/note`, {
            notes: params.notes ?? undefined,
            food_first_course: params.food_first_course ?? undefined,
            food_second_course: params.food_second_course ?? undefined,
            food_side: params.food_side ?? undefined,
            feces_1_quality: params.feces_1_quality ?? undefined,
            feces_2_quality: params.feces_2_quality ?? undefined,
            milk: params.milk ?? undefined,
            nap_1_quality: params.nap_1_quality ?? undefined,
            nap_2_quality: params.nap_2_quality ?? undefined
        });
    }
}