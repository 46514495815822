import React from "react";

export const USERID: string | null = sessionStorage.getItem("id");
export const ROLE: string | null = sessionStorage.getItem("role");
export enum UserRolesEnum {
    Child = "Child",
    Parent = "Parent",
    Teacher = "Teacher",
    Director = "Director",
    Admin = "Admin"
}
export interface isLoggedUser {
    email: string,
    firstName: string,
    isLogged: boolean
    lastName: string
    localizedRoleName: string
    role: UserRolesEnum
    roleName: string
}

export interface OwnScholarsParams {
    paginate?: number;
    sortBy?: string;
    sort?: string;
    searchQuery?: string;
    includeInactive?: boolean;
}

export interface OwnScholarsResponse {
    current_page: number;
    data: ChildData[];
    first_page_url: string;
    last_page_url: string;
    prev_page_url: string;
    next_page_url: string;
    links: {
        url: string;
        label: string;
        active: boolean;
    }[];
    path: string;
    per_page: number;
    last_page: number;
    from: number;
    to: number;
    totale: number;
}

export interface ChildData {
    id: string;
    first_name: string;
    last_name: string;
    birthday: string;
    parent_1: string;
    parent_2?: string | null;
    created_by?: string | null;
    modified_by?: string | null;
    teacher_1: string;
    teacher_2?: string | null;
    is_active: 0 | 1;
    resource_url?: string | null; // Opzionale
    child_notes_url?: string | null; // Opzionale
    parent_1_url?: string | null; // Opzionale
    teacher_1_url?: string | null; // Opzionale
    set_inactive_url?: string | null; // Opzionale
    set_active_url?: string | null; // Opzionale
    delete_url?: string | null; // Opzionale
}

export interface InputFieldsInterface {
    email: string;
    /*password: string;
    passwordConfirm: string;*/
    role: string;
    firstName: string;
    lastName: string;
    phone: string;
    address: string;
    zipcode: string;
    city: string;
    country: string;
}

export interface ChildFieldsInterface {
    first_name: string;
    last_name: string;
    birthday: string;
    parent_1: string;
    parent_2: string;
    teacher_1: string;
    teacher_2: string;
    is_active: boolean;
}

export interface ChildHiddenFieldsInterface {
    parent_1: string;
    parent_2: string;
    teacher_1: string;
    teacher_2: string;
}

export interface UserData {
    current_page: number;
    data: UserInterface[]; // Array di oggetti Teacher
    first_page_url: string;
    last_page_url: string;
    next_page_url: string | null;
    prev_page_url: string | null;
    links: {
        url: string | null;
        label: string;
        active: boolean;
    }[];
    path: string;
    per_page: number;
    last_page: number;
    from: number;
    to: number;
    total: number;
}

export interface UserInterface {
    id: string;
    email: string;
    created_at: string;
    updated_at: string;
    role: number;
    first_name: string;
    last_name: string;
    phone_number: string;
    address: string | null;
    zip_code: string | null;
    city: string | null;
    country: string | null;
    is_active: 0 | 1;
    role_name: string;
    localized_role_name: string;
    resource_url: string;
    children_url: string | null;
    scholars_url: string;
    set_active_url: string;
    delete_url: string;
}

export interface GetDiaryParams {
    paginate?: number;
    sortBy?: string;
    sort?: string;
}

export interface GetDiaryResponse {
    current_page: number;
    data: DiaryData[];
    first_page_url: string | null;
    last_page_url: string | null;
    prev_page_url: string | null;
    next_page_url: string | null;
    links: {
        url: string | null;
        label: string;
        active: boolean;
    }[];
    path: string;
    per_page: number;
    last_page: number;
    from: number;
    to: number;
    total: number;
}

export interface DiaryData {
    id: string,
    notes: string,
    teacher: string,
    child: string,
    created_at: string,
    updated_at: string,
    food_first_course: number,
    food_second_course: number,
    food_side: number,
    feces_1_quality: number,
    feces_2_quality: number,
    milk: number,
    nap_1_quality: number,
    nap_2_quality: number,
    been_read: boolean,
    resource_url: string,
    diary_url: string,
    teacher_url: string,
    child_url: string
}

export interface PostNoteBody {
    id?: string,
    notes?: string,
    food_first_course?: number,
    food_second_course?: number,
    food_side?: number,
    feces_1_quality?: number,
    feces_2_quality?: number,
    milk?: number,
    nap_1_quality?: number,
    nap_2_quality?: number,
    been_read?: boolean
}

export interface QuickButtonProps {
    href?: string;
    icon: React.ReactNode;
    label: string;
}